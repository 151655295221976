import { Alert, Button, Col, Form, Input, notification, Radio, Row, Spin, TreeSelect, Typography } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { useGetCategoriesLazyQuery, useGetCategoryQuery, useUpdateCategoryMutation } from 'graphql/autogenerated'
import React, { useEffect, useMemo } from 'react'
import { MESSAGES } from 'utils/constants'
import { generateSlug, slugRegex } from 'utils/helpers'

const { Title } = Typography

export function UpdateCategoryPage(props: any) {
  const id = props.match.params.id
  const {
    data: categoryData,
    loading: categoryLoading,
    error: categoryError,
  } = useGetCategoryQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        id: id,
      },
    },
  })
  const [fetchGetCategories, { data: categoriesData, loading: categoriesLoading, error: categoriesError, refetch: categoriesRefetch }] = useGetCategoriesLazyQuery({
    fetchPolicy: 'no-cache',
  })

  const convertToTreeForCategory = (list: any) => {
    return (list || []).map(item => {
      return {
        title: item.name,
        value: item.id,
        children: convertToTreeForCategory(item.children),
        disabled: item.id === id,
      }
    })
  }

  const [fetchUpdateCategory, { data: updateData, loading: updateLoading, error: updateError, called: updateCalled }] = useUpdateCategoryMutation({})
  const [form] = Form.useForm()
  const categories = useMemo(() => categoriesData?.getCategories.data || [], [categoriesData])

  const renderCategoriesTree = useMemo(() => {
    return convertToTreeForCategory(categories)
  }, [categories])

  useEffect(() => {
    if (categoryLoading || categoryError) return
    form.setFieldsValue({
      name: categoryData?.getCategory?.name,
      slug: categoryData?.getCategory?.slug,
      parentId: categoryData?.getCategory?.parentId,

      description: categoryData?.getCategory?.description || '',
    })
  }, [categoryLoading, categoryError, categoryData, form])

  useEffect(() => {
    if (!updateCalled || updateLoading) return
    const notiFnc = updateError ? notification.error : notification.success
    notiFnc({
      message: updateError ? MESSAGES.updateFailed : MESSAGES.updateSuccessfully,
    })
  }, [updateCalled, updateError, updateLoading, updateData])

  const handleUpdate = async (values: any) => {
    try {
      const data = await form.validateFields()
      fetchUpdateCategory({
        variables: {
          input: {
            id: id,
            name: data?.name,
            slug: data?.slug,
            parentId: data?.parentId,
            description: data?.description,
          },
        },
      })
    } catch (error) {}
  }

  const handleOnChange = (values: any) => {}

  const handleTitleChange = event => {
    const title = event.target.value as string
    form.setFieldValue('title', title)
    const isCustomSlug = form.getFieldValue('isCustomSlug') as boolean
    if (!isCustomSlug) {
      const slug = generateSlug({ baseSlug: title })
      form.setFieldValue('slug', slug)
      form.validateFields(['slug'])
    }
  }

  useEffect(() => {
    fetchGetCategories({
      variables: {
        input: {
          pagination: {
            page: 0,
            limit: 200,
          },
        },
      },
    })
  }, [])

  return (
    <div style={{ paddingTop: 20, paddingBottom: 20 }}>
      <Title>Chỉnh sửa chuyên mục</Title>
      {(!updateLoading && !!updateError) || (!categoryLoading && !!categoryError && <Alert type="error" message={updateError?.message || categoryError.message} banner />)}
      <Spin tip="Loading" spinning={categoryLoading}>
        <Form layout="vertical" form={form} onFinish={handleUpdate} onFieldsChange={handleOnChange} initialValues={{ isCustomSlug: false }}>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item label="Tên chuyên mục" hasFeedback name={'name'} rules={[{ required: true, message: 'Vui lòng nhập tên chuyên mục' }]}>
                <Input onChange={handleTitleChange} placeholder="Thêm tên chuyên mục"></Input>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Form.Item noStyle name={'isCustomSlug'}>
              <Radio.Group>
                <Radio value={false}>Mặc định</Radio>
                <Radio value={true}>Chỉnh đường dẫn</Radio>
              </Radio.Group>
            </Form.Item>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item shouldUpdate={(preValues, currentValues) => preValues.isCustomSlug !== currentValues.isCustomSlug}>
                {({ getFieldValue }) => {
                  const isDisable = !getFieldValue('isCustomSlug')
                  return (
                    <Form.Item
                      noStyle
                      hasFeedback
                      name={'slug'}
                      rules={[
                        { required: true, message: 'slug là bắt buộc' },
                        {
                          validator: async (_, value) => {
                            if (!value || slugRegex.test(value)) {
                              return Promise.resolve()
                            }
                            return Promise.reject(new Error('Vui lòng nhập một slug hợp lệ (chỉ chữ thường, số và dấu gạch ngang)!'))
                          },
                        },
                      ]}
                      shouldUpdate={(preValues, currentValues) => preValues.isCustomSlug !== currentValues.isCustomSlug}
                    >
                      <Input disabled={isDisable} placeholder="Đường dẫn tĩnh"></Input>
                    </Form.Item>
                  )
                }}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item label="Tên chuyên mục cha" hasFeedback name={'parentId'}>
                <TreeSelect treeDefaultExpandAll={true} treeData={renderCategoriesTree} allowClear></TreeSelect>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item label="Mô tả" name="description">
                <TextArea
                  style={{
                    height: '200px',
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginBottom: '10px' }}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Sửa
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Spin>
    </div>
  )
}
