import { Alert, Button, Divider, Form, notification, Pagination, Popconfirm, Space, Table, Typography } from 'antd'
import { ListWithOptions } from 'app/components/list-with-options'
import { useGetTagsLazyQuery } from 'graphql/autogenerated'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RolesConstants } from 'utils/roles'
import { useDeleteTagMutation, useGetDeletedTagsLazyQuery, useRecoverTagMutation } from '../../../../graphql/autogenerated'
import { DEFAULT_PAGINATION, MESSAGES, TABLE_DROPDOWN_OPTIONS } from '../../../../utils/constants'
import { getPathByName } from '../../../../utils/utils'
import { selectMe } from '../../../redux/user/selectors'
const Title = Typography
export function ListTagPage() {
  const me = useSelector(selectMe)
  const [form] = Form.useForm()
  const history = useHistory()
  const [page, setPage] = useState(1)

  const [fetchGetsTag, { data: tagsData, loading: tagsLoading, error: tagsError, refetch: tagsRefetch }] = useGetTagsLazyQuery({
    fetchPolicy: 'no-cache',
  })

  const [fetchGetDeletedTags, { data: tagsDeletedData, loading: tagsDeletedLoading, error: tagsDeletedError }] = useGetDeletedTagsLazyQuery({
    fetchPolicy: 'no-cache',
  })
  const [fetchDeleteTag, { data: deletingTag, loading: deletingLoading, error: deletingError, called: deletingCalled }] = useDeleteTagMutation({
    fetchPolicy: 'no-cache',
  })

  const [fetchRecoverTag, { data: recoverTagData, loading: recoverTagLoading, error: recoverTagError, called: recoverTagCalled }] = useRecoverTagMutation({
    fetchPolicy: 'no-cache',
  })

  const [dropDowntOpt, setDropDownOpt] = useState(TABLE_DROPDOWN_OPTIONS.active.value)

  const isTagActive = useMemo(() => {
    return dropDowntOpt === TABLE_DROPDOWN_OPTIONS.active.value
  }, [dropDowntOpt])

  const fetchList = () => {
    switch (dropDowntOpt) {
      case TABLE_DROPDOWN_OPTIONS.active.value: {
        fetchGetsTag({
          variables: {
            input: {
              pagination: {
                limit: DEFAULT_PAGINATION.pageSize,
                page: page,
                totalCount: tagsData?.getTags.pagination.totalCount,
              },
            },
          },
        })
        return
      }
      case TABLE_DROPDOWN_OPTIONS.deleted.value: {
        fetchGetDeletedTags({
          variables: {
            input: {
              pagination: {
                limit: DEFAULT_PAGINATION.pageSize,
                page: page,
                totalCount: tagsDeletedData?.getDeletedTags.pagination.totalCount,
              },
            },
          },
        })
      }
    }
  }

  const handleEdit = useCallback(
    record => {
      const pathname = getPathByName('tag-edit', { id: record.id })
      history.push(pathname)
    },
    [history],
  )

  const handleChangePage = page => {
    setPage(page)
  }

  const handleDelete = id => {
    fetchDeleteTag({
      variables: {
        input: { id: id },
      },
    })
  }

  const handleRecoverTag = id => {
    fetchRecoverTag({
      variables: {
        input: {
          id: id,
        },
      },
    })
  }

  const tableResource = useMemo(() => {
    return isTagActive ? tagsData?.getTags.data : tagsDeletedData?.getDeletedTags.data
  }, [isTagActive, tagsData, tagsDeletedData])

  const totalRecord = useMemo(() => {
    return isTagActive ? tagsData?.getTags.pagination.totalCount : tagsDeletedData?.getDeletedTags.pagination.totalCount
  }, [isTagActive, tagsData, tagsDeletedData])

  const colums = [
    {
      title: 'Tên thẻ',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        if (isTagActive) {
          return (
            <Space size="middle">
              <Button type="primary" onClick={() => handleEdit(record)}>
                Sửa
              </Button>
              <Popconfirm title="Bạn có chắc xoá thẻ này không?" onConfirm={() => handleDelete(record.id)}>
                <Button type="primary">Xóa</Button>
              </Popconfirm>
            </Space>
          )
        }
        return (
          <Space size="middle">
            <Button type="primary" onClick={() => handleRecoverTag(record.id)}>
              Khôi phục
            </Button>
          </Space>
        )
      },
    },
  ]

  useEffect(() => {
    fetchList()
  }, [dropDowntOpt, page])

  useEffect(() => {
    if (!deletingCalled || deletingLoading) return

    const notiFnc = deletingError ? notification.error : notification.success
    notiFnc({ message: deletingError ? MESSAGES.deleteFailed : MESSAGES.deleteSuccessfully })
    fetchList()
  }, [deletingCalled, deletingError, deletingLoading])

  useEffect(() => {
    if (!recoverTagCalled || recoverTagLoading) return
    const notiFnc = recoverTagError ? notification.error : notification.success
    notiFnc({ message: recoverTagError ? MESSAGES.recoverFailed : MESSAGES.recoverSuccessfully })
    fetchList()
  }, [recoverTagCalled, recoverTagError, recoverTagLoading])

  return (
    <div style={{ paddingTop: 20, paddingBottom: 20 }}>
      <Title>Danh sách thẻ</Title>
      <Divider plain></Divider>
      {console.log(tableResource)}
      {!tagsLoading && tagsError && <Alert type="error" message={tagsError?.message} banner />}
      <ListWithOptions isDeletedList={!!me.scp?.includes(RolesConstants.getBlogsWasDeleted)} onChange={setDropDownOpt} />

      <Form form={form} component={false}>
        <Table dataSource={tableResource} loading={tagsLoading} pagination={false} columns={colums} rowKey="id" />
      </Form>
      <div style={{ marginTop: 5 }}>
        <Pagination current={page} onChange={handleChangePage} pageSize={DEFAULT_PAGINATION.pageSize} total={totalRecord} />
      </div>
    </div>
  )
}
