import React, { useCallback } from 'react'
import { Menu } from 'antd'

import { MenuDashboard } from 'types/Utils'
import '../style.module.css'
import type { MenuProps } from 'antd'
import { navigationMap } from 'utils/navigation'
type MenuItem = Required<MenuProps>['items'][number]

interface NavigationLeftProps {
  navigationData: MenuItem[]
  menuKeySelected: string[]
  menuKeyOpened: string[]
  theme: any
  onActiveMenuItem: (key: string, type: MenuDashboard) => void
  onRedirectPage: (path: string, item?: boolean) => void
  onSetSelectedMenu: (key: string[]) => void
}

export function NavigationLeft(props: NavigationLeftProps) {
  const { menuKeySelected, menuKeyOpened, theme, onActiveMenuItem, onRedirectPage, navigationData, onSetSelectedMenu } = props
  const handleRedirectPage = useCallback(
    (path: string, key: string[]) => {
      if (path) {
        onRedirectPage(path)
        onSetSelectedMenu(key)
        // onActiveMenuItem(index, MenuDashboard.active)
      }
    },
    [onRedirectPage, onActiveMenuItem],
  )

  return (
    <Menu
      theme={theme}
      defaultSelectedKeys={menuKeySelected}
      defaultOpenKeys={menuKeyOpened}
      mode="inline"
      items={navigationData}
      onClick={e => {
        const key = e.key
        const nav = navigationMap.get(key)
        const item = nav?.props || {}
        if (!!item.callback) {
          item.callback()
        } else {
          handleRedirectPage(item.path || '', item.keyPath)
        }
      }}
    />
  )
}
