import React from 'react'
import { BREAKPOINT } from 'utils/constants'
import { RawToHtml } from '../raw-to-html/Html'
import './style.scss'

interface PreviewBlogProps {
  content: string
  screen?: keyof typeof BREAKPOINT
}
export const PreviewBlog = ({ screen = 'mobile', content }: PreviewBlogProps) => {
  const screenWidth = BREAKPOINT[screen]
  return (
    <div style={{ width: `${screenWidth}px` }} className="mobile-preview">
      <RawToHtml data={content} />
    </div>
  )
}
