import { IsDev } from 'utils'

const HTTP_PROTOCOL = IsDev() ? 'http://' : 'https://'
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
const SOCKET_ENDPOINT = process.env.REACT_APP_SOCKET_ENDPOINT
const isLocal = (API_ENDPOINT || '').startsWith('localhost:')
export const BACKEND_API = {
  domain: API_ENDPOINT,
  restAPI: `${IsDev() || isLocal ? 'http://' : 'https://'}${API_ENDPOINT}/api`,
  api: `${IsDev() ? 'http://' : 'https://'}${API_ENDPOINT}/graphql`,
  graphqlWs: `${IsDev() ? 'ws://' : 'wss://'}${API_ENDPOINT}/graphql`,
  socketWs: `${IsDev() ? 'ws://' : 'wss://'}${SOCKET_ENDPOINT}`,
}

export const MEETING_DOMAIN = process.env.REACT_APP_MEETING_ENDPOINT
export const MEETING_SDK = `${IsDev() ? 'https://' : 'https://'}${MEETING_DOMAIN}/external_api.js`

export const ROOM_EXPIRED = Number(process.env.REACT_APP_ROOM_EXPIRED) // seconds
export const isPreventSearchBot = process.env.REACT_APP_PREVENT_SEARCH_BOT === 'true'

export const configs = {
  frontendDomain: IsDev() ? 'http://localhost:3000' : !!process.env.REACT_APP_FRONTEND_DOMAIN ? HTTP_PROTOCOL + process.env.REACT_APP_FRONTEND_DOMAIN : 'https://noinghe.com',
}
