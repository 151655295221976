import { useMeLazyQuery } from 'graphql/autogenerated'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useInjectReducer } from 'utils/redux-injectors'
import { authentication } from '../../../utils/authentication'
import { selectFetchMe, selectLoadingMe, selectMe } from './selectors'
import { actions, reducer, sliceKey } from './slice'
import { IMe } from './types'

export function useMeStore() {
  useInjectReducer({ key: sliceKey, reducer: reducer })
  const [handleGetme, { data, loading, error, called }] = useMeLazyQuery()

  const dispatch = useDispatch()

  const isMeLoading = useSelector(selectLoadingMe)
  const me = useSelector(selectMe)
  const isFetchMe = useSelector(selectFetchMe)

  const isFetchMeSuccessfully = useMemo(() => {
    return !loading && !error && data
  }, [data, loading, error])

  useEffect(() => {
    if(error ){
      authentication.logout();
      window.location.replace('/sign-in')
    }
    const isExpired=authentication.removeTokenIfExpired()
    
    if (!isFetchMe&&!isExpired) {
      handleGetme()
      dispatch(actions.fetchLoginUser())
    }

    if (called && !loading) {
      let _me = {}
      if (isFetchMeSuccessfully) {
        _me = data!.me as Partial<IMe>
      }
      dispatch(actions.loginUserDataLoaded({ me: _me }))
    }
  }, [isFetchMeSuccessfully, dispatch, data, isFetchMe, handleGetme, loading, called])

  const handleInjectMe = (me: Partial<IMe>) => {
    dispatch(actions.loginUserDataLoaded({ me }))
  }

  const isLogin = useMemo(() => {
    return !!me.id
  }, [me])

  return {
    isMeLoading,
    me,
    isLogin,
    isFetchMe,
    onInjectMe: handleInjectMe,
    refreshMe: handleGetme,
  }
}
