export interface OrderItem {
    order?: number | null;
}

export function CalculateNewPosition(
    orderList: OrderItem[],
    draggedItem: OrderItem,
    targetPosition: number
): number {
    // Loại bỏ mục đang bị kéo ra khỏi danh sách và xử lý các giá trị order null
    const validOrders = orderList
        .filter(item => item !== draggedItem && item.order !== null) as OrderItem[];

    if (validOrders.length === 0) {
        return 0;
    }

    // Nếu kéo thả vào vị trí đầu tiên
    if (targetPosition === 0) {
        return (validOrders[0].order as number) - 100;
    }

    // Nếu kéo thả vào vị trí cuối cùng
    if (targetPosition >= validOrders.length) {
        return (validOrders[validOrders.length - 1].order as number) + 100;
    }

    // Tìm giá trị order trước và sau vị trí đích
    const prevOrder = targetPosition > 0 ? validOrders[targetPosition - 1].order : null;
    const nextOrder = targetPosition < validOrders.length ? validOrders[targetPosition].order : null;

    if (prevOrder === null) {
        return (nextOrder as number) - 100;
    }

    if (nextOrder === null) {
        return (prevOrder as number) + 100;
    }

    return ((prevOrder as number) + (nextOrder as number)) / 2;
}
