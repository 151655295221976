import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { Alert, Button, Divider, Form, Pagination, Popconfirm, Space, Table, Typography } from 'antd'
import { useDeleteSubModuleMutation, useGetModuleDetailLazyQuery, useUpdateModuleMutation } from 'graphql/autogenerated'
import moment from 'moment'

import { CloudDownloadOutlined, DeleteOutlined, FilterOutlined, PlusOutlined } from '@ant-design/icons'
import { CalculateNewPosition } from 'app/components/dnd/CalculateNewPosition'
import DragHandle from 'app/components/dnd/DragHandle'
import Row from 'app/components/dnd/Row'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { DEFAULT_PAGINATION, ROUTE_NAMES } from 'utils/constants'
import { getPathByName } from 'utils/utils'

const { Title } = Typography
export function ListSubmoduleDetailPage(props) {
  const courseId = props.match.params.courseId
  const moduleId = props.match.params.moduleId

  const [form] = Form.useForm()
  const history = useHistory()
  const [page, setPage] = useState(1)

  const [fetchGetModuleDetail, { data: moduleDetailData, loading: moduleDetailLoading, error: moduleDetailError, refetch: moduleDetailRefetch }] = useGetModuleDetailLazyQuery({
    fetchPolicy: 'no-cache',
  })

  const [fetchDeleteSubModule, { data: deletingData, loading: deletingLoading, error: deletingError, called: deletingCalled }] = useDeleteSubModuleMutation({ fetchPolicy: 'no-cache' })

  const [fetchUpdateModule, { data: updateModuleDate, loading: updateModuleLoading, error: errorModuleError, called: calledModuleCalled }] = useUpdateModuleMutation({ fetchPolicy: 'no-cache' })

  const tableResource = useMemo(() => {
    return moduleDetailData?.getModule.data.subModules || []
  }, [moduleDetailData])

  const totalRecord = useMemo(() => {
    return moduleDetailData?.getModule.data.subModules?.length || 0
  }, [moduleDetailData])

  const handleDelete = useCallback(record => {
    fetchDeleteSubModule({
      variables: {
        input: { id: record.id },
      },
    })
  }, [])

  const gotoListSubmodule = useCallback(
    record => {
      const pathname = getPathByName(ROUTE_NAMES.submoduleList, { courseId: courseId, moduleId: moduleId })
      history.push({ pathname })
    },
    [history],
  )

  const handleEdit = useCallback(
    record => {
      const pathname = getPathByName(ROUTE_NAMES.submoduleEdit, { courseId: courseId, moduleId: moduleId, submoduleId: record.id })
      history.push({ pathname })
    },
    [history],
  )

  const handleChangePage = useCallback(page => {
    setPage(page)
  }, [])

  const handleGotoCreateSubModule = useCallback(() => {
    const pathname = getPathByName(ROUTE_NAMES.submoduleAdd, { courseId: courseId, moduleId: moduleId })
    history.push({ pathname })
  }, [])

  const columns = useMemo(() => {
    return [
      {
        key: 'order',
        width: 80,
        render: () => <DragHandle />,
      },
      {
        title: 'Tên Module',
        dataIndex: 'title',
        key: 'title',
        render: (_, record) => {
          return <div>{record.module?.title}</div>
        },
      },
      {
        title: 'Tên subModule',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: 'Mô tả',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'type subModule',
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: 'Ngày tạo',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (time, record) => {
          return <div>{moment(time).format('MM-DD-YYYY HH:mm:ss')}</div>
        },
      },
      {
        title: 'Cập nhật',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        render: (time, record) => {
          return <div>{moment(time).format('MM-DD-YYYY HH:mm:ss')}</div>
        },
      },
      {
        title: 'Hành động',
        key: 'Action',
        render: (_, record) => {
          console.log('🚀 ~ columns ~ record:', record)
          return (
            <Space size={'middle'}>
              <Button type="primary" onClick={() => handleEdit(record)}>
                Sửa
              </Button>
              <Popconfirm title="Bạn có chắc xóa module này không?" onConfirm={() => handleDelete(record)}>
                <Button type="primary">Xóa</Button>
              </Popconfirm>
            </Space>
          )
        },
      },
    ]
  }, [])

  useEffect(() => {
    fetchGetModuleDetail({
      variables: {
        input: {
          id: moduleId,
        },
      },
    })
  }, [moduleId, page, updateModuleDate, deletingData])

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = tableResource.findIndex(record => record.order === active.id)
      const overIndex = tableResource.findIndex(record => record.order === over?.id)
      let newOrder = 1000
      if (tableResource.length > 0) {
        newOrder = CalculateNewPosition(tableResource, tableResource[activeIndex], overIndex)
      }
      fetchUpdateModule({
        variables: {
          input: {
            id: tableResource[activeIndex].id,
            order: newOrder,
          },
        },
      })
    }
  }

  return (
    <div style={{ paddingTop: 20, paddingBottom: 20 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title>Danh sách subModule</Title>
        <div style={{ display: 'flex', gap: 16 }}>
          <Button style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 4 }}>
            <DeleteOutlined />
            <div>Xoá</div>
          </Button>
          <Button style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 4 }}>
            <FilterOutlined />
            <div>Filter</div>
          </Button>
          <Button style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 4 }}>
            <CloudDownloadOutlined />
            <div>Export</div>
          </Button>
          <Button style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 4 }} type="primary" onClick={handleGotoCreateSubModule}>
            <PlusOutlined />
            <div>Thêm SubModule</div>
          </Button>
        </div>
      </div>
      <Divider plain></Divider>
      {!moduleDetailLoading && moduleDetailError && <Alert type="error" message={moduleDetailError?.message} banner />}
      <Form form={form} component={false}>
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext items={tableResource.map(i => i.order || 100)} strategy={verticalListSortingStrategy}>
            <Table
              rowKey="order"
              dataSource={tableResource}
              components={{
                body: {
                  row: Row,
                },
              }}
              loading={moduleDetailLoading}
              pagination={false}
              columns={columns}
            />
          </SortableContext>
        </DndContext>
      </Form>
      <div style={{ marginTop: 5 }}>
        <Pagination current={page} onChange={handleChangePage} pageSize={DEFAULT_PAGINATION.pageSize} total={totalRecord} />
      </div>
    </div>
  )
}
