import React from 'react'
import { ReactQuillProps } from 'react-quill'
//
import { message } from 'antd'
import { memo, useCallback, useMemo, useRef, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'
import { uploadFile } from 'utils/services/file.service'
import { EditorToolbar, formats } from './EditorToolbar'

const ReactQuill = React.lazy(() => import('./react-quill'))

const MemorizedReactQuill = memo(ReactQuill)
const randomUid = () => Math.floor(Math.random() * 100000)

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

interface Props extends ReactQuillProps {
  id?: string
  error?: boolean
  simple?: boolean
  // sx?: BoxProps
  onLoading?: (loading: boolean) => void
}

export function Editor({ id, error, value, onChange, simple = false, onLoading, ...other }: Props) {
  const [loadingCount, setLoadingCount] = useState(0)
  const quillRef = useRef<any>()
  const ID = useMemo(() => `react-quill-${id || randomUid().toString()}`, [id])
  const containerId = useMemo(() => `#${ID}`, [ID])
  // const { onUploadImage, onUploadVideo } = useUploadMedia({
  //   containerType: STORAGE_CONTAINERS.OTHER,
  // })

  const increaseLoading = () => {
    setLoadingCount(prev => prev + 1)
  }

  const decreaseLoading = () => {
    setLoadingCount(prev => prev - 1)
  }

  const imageHandler = useCallback((e: any) => {
    const editor = quillRef.current.getEditor()
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()

    input.onchange = async () => {
      increaseLoading()
      try {
        const file = input.files![0]
        if (/^image\//.test(file.type)) {
          try {
            const { url } = await uploadFile(file)
            editor.insertEmbed(editor.getSelection(), 'image', url)
            message.success('Upload hình ảnh thành công')
          } catch (error) {
            message.success('Upload hình ảnh thất bại')
          }
        } else {
          // ErrorToast('You could only upload images.');
        }
      } catch (error) {}
      decreaseLoading()
    }
  }, [])

  const videoHandler = useCallback((e: any) => {
    const editor = quillRef.current.getEditor()
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'video/*')
    input.click()

    input.onchange = async () => {
      increaseLoading()
      try {
        const file = input.files![0]
        if (/^video\//.test(file.type)) {
          // const result = await onUploadVideo(file)
          // if (!result) return
          // editor.insertEmbed(editor.getSelection(), 'video', {
          //   url: result.url,
          //   thumbnail: result.thumbnail,
          // } as any)
        } else {
          // ErrorToast('You could only upload images.');
        }
      } catch (error) {}
      decreaseLoading()
    }
  }, [])

  const isUploading = useMemo(() => !!loadingCount, [loadingCount])

  const modules = useMemo(() => {
    return {
      syntax: true,
      toolbar: {
        container: containerId,
        handlers: {
          image: imageHandler,
          // video: videoHandler,
        },
      },
      history: {
        delay: 500,
        maxStack: 100,
        userOnly: true,
      },
      clipboard: {
        matchVisual: false,
      },
    }
  }, [])

  useUpdateEffect(() => {
    !!onLoading && onLoading(isUploading)
  }, [isUploading])

  return (
    <RootStyle>
      {/* {isUploading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )} */}

      <EditorToolbar id={ID} isSimple={simple} />
      <MemorizedReactQuill ref={quillRef} value={value} onChange={onChange} modules={modules} formats={formats} placeholder="Write something awesome..." {...other} />
    </RootStyle>
  )
}

export const RootStyle = styled('div')(({ theme }) => {
  return {
    overflow: 'hidden',
    position: 'relative',
    // borderRadius: theme.shape.borderRadius,
    // border: `solid 1px ${theme.palette.grey[500_32]}`,
    '& .ql-container.ql-snow': {
      borderColor: 'transparent',
      height: '650px',
      // ...theme.typography.body1,
      // fontFamily: theme.typography.fontFamily,
    },
    '& .ql-editor': {
      '&.ql-blank::before': {
        fontStyle: 'normal',
        // color: theme.palette.text.disabled,
      },
      '& pre.ql-syntax': {
        // ...theme.typography.body2,
        // padding: theme.spacing(2),
        // borderRadius: theme.shape.borderRadius,
        // backgroundColor: theme.palette.grey[900],
      },
    },
  }
})
