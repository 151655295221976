/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { GlobalStyle } from 'styles/global-styles'

import { LayoutDefault } from 'app/layouts/layout-default'
import { RouteExtension } from 'router/Route'
import { NotFoundPage } from './components/not-found-page/Loadable'
import { LoginPage } from './pages/login-page/Loadable'
import { SignUp } from './pages/sign-up'

export function App() {
  return (
    <BrowserRouter>
      <Helmet titleTemplate="%s - Admin - NoiNghe" defaultTitle="Admin - NoiNghe">
        <meta name="description" content="A Admin - NoiNghe application" />
      </Helmet>

      <Switch>
        {/* <RouteExtension exact path="/" name="" component={HomePage} /> */}
        <Route path="/not-found" component={NotFoundPage} />
        <Route path="/sign-in" component={LoginPage} />
        <Route path="/sign-up" component={SignUp} />
        <RouteExtension path="/" isAuth={true} component={LayoutDefault} />
      </Switch>
      <GlobalStyle />
    </BrowserRouter>
  )
}
