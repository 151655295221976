import { Alert, Button, Divider, Form, Pagination, Popconfirm, Space, Table, Typography, notification } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import '../style.scss'

import { ListWithOptions } from 'app/components/list-with-options'
import { selectMe } from 'app/redux/user/selectors'
import { useDeleteCourseCategoryMutation, useGetCourseCategoriesLazyQuery, useGetDeletedCourseCategoriesLazyQuery, useRecoverCourseCategoryMutation } from 'graphql/autogenerated'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { DEFAULT_PAGINATION, MESSAGES, TABLE_DROPDOWN_OPTIONS } from 'utils/constants'
import { RolesConstants } from 'utils/roles'
import { getPathByName } from 'utils/utils'

const { Title } = Typography
export function ListCourseCategoryPage() {
  const me = useSelector(selectMe)
  const [form] = Form.useForm()
  const history = useHistory()
  const [page, setPage] = useState(1)
  const [fetchGetCourseCategories, { data: courseCategoriesData, loading: courseCategoriesLoading, error: courseCategoriesError }] = useGetCourseCategoriesLazyQuery({
    fetchPolicy: 'no-cache',
  })
  const [fetchGetCourseCategoriesDeleted, { data: courseCategoriesDeletedData, loading: courseCategoriesDeletedLoading, error: courseCategoriesDeletedError }] = useGetDeletedCourseCategoriesLazyQuery(
    {
      fetchPolicy: 'no-cache',
      variables: {
        input: {
          pagination: {
            limit: 100,
            page: 1,
          },
        },
      },
    },
  )
  const [fetchDeleteCourseCategory, { data: deletingData, loading: deletingLoading, error: deletingError, called: deletingCalled }] = useDeleteCourseCategoryMutation({
    fetchPolicy: 'no-cache',
  })

  const [fetchRecoverCourseCategory, { data: recoverCourseCategoryData, loading: recoverCourseCategoryLoading, error: recoverCourseCategoryError, called: recoverCourseCategoryCalled }] =
    useRecoverCourseCategoryMutation({
      fetchPolicy: 'no-cache',
    })

  const [dropdownOpt, setDropdownOpt] = useState(TABLE_DROPDOWN_OPTIONS.active.value)

  const isCategoryActive = useMemo(() => {
    return dropdownOpt === TABLE_DROPDOWN_OPTIONS.active.value
  }, [dropdownOpt])

  const fetchList = () => {
    switch (dropdownOpt) {
      case TABLE_DROPDOWN_OPTIONS.active.value: {
        fetchGetCourseCategories({
          variables: {
            input: {
              pagination: { limit: DEFAULT_PAGINATION.pageSize!, page: page, totalCount: courseCategoriesData?.getCourseCategories.pagination.totalCount  },
            },
          },
        })
        return
      }
      case TABLE_DROPDOWN_OPTIONS.deleted.value: {
        fetchGetCourseCategoriesDeleted({
          variables: {
            input: {
              pagination: { limit: DEFAULT_PAGINATION.pageSize!, page: page, totalCount: courseCategoriesDeletedData?.getDeletedCourseCategories.pagination.totalCount },
            },
          },
        })
        return
      }
    }
  }

  useEffect(() => {
    fetchList()
  }, [dropdownOpt, page])

  useEffect(() => {
    if (!deletingCalled || deletingLoading) return
    const notiFnc = deletingError ? notification.error : notification.success
    notiFnc({ message: deletingError ? MESSAGES.deleteFailed : MESSAGES.deleteSuccessfully })
    fetchList()
  }, [deletingData, deletingError, deletingLoading, deletingCalled])

  useEffect(() => {
    if (!recoverCourseCategoryCalled || recoverCourseCategoryLoading) return
    const notiFnc = recoverCourseCategoryError ? notification.error : notification.success
    notiFnc({
      message: recoverCourseCategoryError ? MESSAGES.recoverFailed : MESSAGES.recoverSuccessfully,
    })
    fetchList()
  }, [recoverCourseCategoryData, recoverCourseCategoryError, recoverCourseCategoryLoading, recoverCourseCategoryCalled])

  const handleEdit = useCallback(
    record => {
      const pathname = getPathByName('course-category-edit', { id: record.id })
      history.push({ pathname })
    },
    [history],
  )

  const handleChangePage = page => {
    setPage(page)
  }

  const handleDelete = id => {
    fetchDeleteCourseCategory({
      variables: {
        input: { id: id },
      },
    })
  }

  const handleRecoverCourseCategory = (id: string) => {
    fetchRecoverCourseCategory({
      variables: {
        input: {
          id: id,
        },
      },
    })
  }

  const tableResource = useMemo(() => {
    return (isCategoryActive ? courseCategoriesData?.getCourseCategories.data : courseCategoriesDeletedData?.getDeletedCourseCategories.data) || ([] as any)
  }, [isCategoryActive, courseCategoriesData, courseCategoriesDeletedData])

  const totalRecord = useMemo(() => {
    return (isCategoryActive ? courseCategoriesData?.getCourseCategories.pagination.totalCount : courseCategoriesDeletedData?.getDeletedCourseCategories.pagination.totalCount) || ([] as any)
  }, [isCategoryActive, courseCategoriesData, courseCategoriesDeletedData])

  const columns = [
    {
      title: 'Tên danh mục',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (time, record) => {
        return <div>{moment(time).format('MM-DD-YYYY HH:mm:ss')}</div>
      },
    },
    {
      title: 'Cập nhật',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (time, record) => {
        return <div>{moment(time).format('MM-DD-YYYY HH:mm:ss')}</div>
      },
    },
    {
      title: 'Hành động',
      key: 'Action',
      render: (_, record) => {
        if (isCategoryActive) {
          return (
            <Space size={'middle'}>
              <Button type="primary" onClick={() => handleEdit(record)}>
                Sửa
              </Button>
              <Popconfirm title="Bạn có chắc xóa chuyên mục này không?" onConfirm={() => handleDelete(record.id)}>
                <Button type="primary">Xóa</Button>
              </Popconfirm>
            </Space>
          )
        }
        return (
          <Space size="middle">
            <Button type="primary" onClick={() => handleRecoverCourseCategory(record.id)}>
              Khôi phục
            </Button>
          </Space>
        )
      },
    },
  ]
  return (
    <div style={{ paddingTop: 20, paddingBottom: 20 }}>
      <Title>Danh sách chuyên mục khóa học</Title>
      <Divider plain></Divider>
      {!courseCategoriesLoading && !courseCategoriesDeletedLoading && courseCategoriesError && (
        <Alert type="error" message={courseCategoriesError?.message || courseCategoriesDeletedError?.message} banner />
      )}
      <ListWithOptions isDeletedList={!!me.scp?.includes(RolesConstants.getCourseCategoriesWasDeleted)} onChange={setDropdownOpt} />
      <Form form={form} component={false}>
        <Table dataSource={tableResource} loading={courseCategoriesLoading || courseCategoriesDeletedLoading} pagination={false} columns={columns} rowKey="id" />
      </Form>
      <div style={{ marginTop: 5 }}>
        <Pagination current={page} onChange={handleChangePage} pageSize={DEFAULT_PAGINATION.pageSize} total={totalRecord} />
      </div>
    </div>
  )
}
