import React from 'react';

interface RowContextType {
  setActivatorNodeRef?: (node: HTMLElement | null) => void;
  listeners?: Record<string, any>;
}

const RowContext = React.createContext<RowContextType>({});

export default RowContext;
