import { Alert, Button, Divider, Form, Pagination, Popconfirm, Space, Table, Typography, notification } from 'antd'
import { useDeleteCourseMutation, useGetCoursesLazyQuery, useGetDeletedCoursesLazyQuery, useRecoverCourseMutation } from 'graphql/autogenerated'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import '../style.scss'

import { ListWithOptions } from 'app/components/list-with-options'
import { selectMe } from 'app/redux/user/selectors'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { DEFAULT_PAGINATION, MESSAGES, ROUTE_NAMES, TABLE_DROPDOWN_OPTIONS } from 'utils/constants'
import { RolesConstants } from 'utils/roles'
import { getPathByName } from 'utils/utils'

const { Title } = Typography
export function ListCoursePage() {
  const me = useSelector(selectMe)
  const [form] = Form.useForm()
  const history = useHistory()
  const [page, setPage] = useState(1)
  const [fetchGetCourses, { data: coursesData, loading: coursesLoading, error: coursesError, refetch: coursesRefetch }] = useGetCoursesLazyQuery({
    fetchPolicy: 'no-cache',
  })
  const [fetchGetCoursesDeleted, { data: coursesDeletedData, loading: coursesDeletedLoading, error: coursesDeletedError, refetch: coursesDeletedRefetch }] = useGetDeletedCoursesLazyQuery({
    fetchPolicy: 'no-cache',
  })
  const [fetchDeleteCourse, { data: deletingData, loading: deletingLoading, error: deletingError, called: deletingCalled }] = useDeleteCourseMutation({ fetchPolicy: 'no-cache' })

  const [fetchRecoverCourse, { data: recoverCourseData, loading: recoverCourseLoading, error: recoverCourseError, called: recoverCourseCalled }] = useRecoverCourseMutation({
    fetchPolicy: 'no-cache',
  })
  const [dropdownOpt, setDropdownOpt] = useState(TABLE_DROPDOWN_OPTIONS.active.value)

  const isCourseActive = useMemo(() => {
    return dropdownOpt === TABLE_DROPDOWN_OPTIONS.active.value
  }, [dropdownOpt])

  const fetchList = () => {
    switch (dropdownOpt) {
      case TABLE_DROPDOWN_OPTIONS.active.value: {
        fetchGetCourses({
          variables: {
            input: {
              pagination: { limit: DEFAULT_PAGINATION.pageSize!, page: page, totalCount: coursesData?.getCourses.pagination.totalCount },
            },
          },
        })
        return
      }
      case TABLE_DROPDOWN_OPTIONS.deleted.value: {
        fetchGetCoursesDeleted({
          variables: {
            input: {
              pagination: { limit: DEFAULT_PAGINATION.pageSize!, page: page, totalCount: coursesDeletedData?.getDeletedCourses.pagination.totalCount  },
            },
          },
        })
        return
      }
    }
  }

  useEffect(() => {
    fetchList()
  }, [dropdownOpt, page])

  useEffect(() => {
    if (!deletingCalled || deletingLoading) return
    const notiFnc = deletingError ? notification.error : notification.success
    notiFnc({ message: deletingError ? MESSAGES.deleteFailed : MESSAGES.deleteSuccessfully })
    fetchList()
  }, [deletingData, deletingError, deletingLoading, deletingCalled])

  useEffect(() => {
    if (!recoverCourseCalled || recoverCourseLoading) return
    const notiFnc = recoverCourseError ? notification.error : notification.success
    notiFnc({
      message: recoverCourseError ? MESSAGES.recoverFailed : MESSAGES.recoverSuccessfully,
    })
    fetchList()
  }, [recoverCourseData, recoverCourseError, recoverCourseLoading, recoverCourseCalled])

  const handleGotoModule=useCallback(
    record => {
      const pathname = getPathByName(ROUTE_NAMES.moduleList, { courseId: record.id })
      history.push({ pathname })
    },
    [history],
  )

  const handleEdit = useCallback(
    record => {
      const pathname = getPathByName(ROUTE_NAMES.courseEdit, { id: record.id })
      history.push({ pathname })
    },
    [history],
  )

  const handleChangePage = page => {
    setPage(page)
  }

  const handleDelete = id => {
    fetchDeleteCourse({
      variables: {
        input: { id: id },
      },
    })
  }

  const handleRecover = (id: string) => {
    fetchRecoverCourse({
      variables: {
        input: {
          id: id,
        },
      },
    })
  }

  const tableResource = useMemo(() => {
    return (isCourseActive ? coursesData?.getCourses.data : coursesDeletedData?.getDeletedCourses.data) || ([] as any)
  }, [isCourseActive, coursesData, coursesDeletedData])

  const totalRecord = useMemo(() => {
    return (isCourseActive ? coursesData?.getCourses.pagination.totalCount : coursesDeletedData?.getDeletedCourses.pagination.totalCount) || ([] as any)
  }, [isCourseActive, coursesData, coursesDeletedData])

  const columns = [
    {
      title: 'Tên khóa học',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (time, record) => {
        return <div>{moment(time).format('MM-DD-YYYY HH:mm:ss')}</div>
      },
    },
    {
      title: 'Cập nhật',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (time, record) => {
        return <div>{moment(time).format('MM-DD-YYYY HH:mm:ss')}</div>
      },
    },
    {
      title: 'Hành động',
      key: 'Action',
      render: (_, record) => {
        if (isCourseActive) {
          return (
            <Space size={'middle'}>
              <Button type="primary" onClick={() => handleGotoModule(record)}>
                danh sách module
              </Button>
              <Button type="primary" onClick={() => handleEdit(record)}>
                Sửa
              </Button>
              <Popconfirm title="Bạn có chắc xóa chuyên mục này không?" onConfirm={() => handleDelete(record.id)}>
                <Button type="primary">Xóa</Button>
              </Popconfirm>
            </Space>
          )
        }
        return (
          <Space size="middle">
            <Button type="primary" onClick={() => handleRecover(record.id)}>
              Khôi phục
            </Button>
          </Space>
        )
      },
    },
  ]
  return (
    <div style={{ paddingTop: 20, paddingBottom: 20 }}>
      <Title>Danh sách Khóa học</Title>
      <Divider plain></Divider>
      {!coursesLoading && !coursesDeletedLoading && (coursesError || coursesDeletedError) && <Alert type="error" message={coursesError?.message || coursesDeletedError?.message} banner />}
      <ListWithOptions isDeletedList={!!me.scp?.includes(RolesConstants.getCategoriesWasDeleted)} onChange={setDropdownOpt} />
      <Form form={form} component={false}>
        <Table dataSource={tableResource} loading={coursesLoading || coursesDeletedLoading} pagination={false} columns={columns} rowKey="id" />
      </Form>
      <div style={{ marginTop: 5 }}>
        <Pagination current={page} onChange={handleChangePage} pageSize={DEFAULT_PAGINATION.pageSize} total={totalRecord} />
      </div>
    </div>
  )
}
