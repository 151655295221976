import { isUUID } from './utils'

export const transformItemsToListSelect = (tags?: any[] | null) => {
  return (tags || []).map(tag => tag.id)
}

export function transformListSelectToCategories(categories: string[]) {
  return categories.map(id => {
    return {
      id: id,
    }
  })
}

export function transformListSelectToTags(tags: string[]) {
  return tags.map(tag => {
    const isNew = !isUUID(tag)
    return {
      id: isNew ? null : tag,
      name: tag,
    }
  })
}
