import { Alert, Button, Divider, Form, Pagination, Popconfirm, Space, Table, Tag, Typography, notification } from 'antd'
import { ListWithOptions } from 'app/components/list-with-options'
import { selectMe } from 'app/redux/user/selectors'
import { useDeleteListenerRegisterMutation, useGetDeletedListenersRegisterLazyQuery, useGetListenersRegisterLazyQuery, useRecoverListenerRegisterMutation, useUpdateListenerRegisterMutation } from 'graphql/autogenerated'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { DATE_TIME_FORMAT, DEFAULT_PAGINATION, MESSAGES, TABLE_DROPDOWN_OPTIONS } from 'utils/constants'
import dayjs from 'utils/dayjs'
import { RolesConstants } from 'utils/roles'


const Title = Typography

export function ListCandidateListenersPage() {
  const me = useSelector(selectMe)
  const [form] = Form.useForm()

  const [page, setPage] = useState(1)
  const [dropDownOpt, setDropdownOpt] = useState(TABLE_DROPDOWN_OPTIONS.active.value)

  const isListenerActive = useMemo(() => {
    return dropDownOpt === TABLE_DROPDOWN_OPTIONS.active.value
  }, [dropDownOpt])

  const [fetchGetListeners, { data: listenersData, loading: listenersLoading, error: listenersError, refetch: listenersRefetch }] = useGetListenersRegisterLazyQuery({
    fetchPolicy: 'no-cache',
  })

  const [fetchGetDeletedListeners, { data: listenersDeletedData, loading: listenersDeletedLoading, error: listenersDeletedError }] = useGetDeletedListenersRegisterLazyQuery({
    fetchPolicy: 'no-cache',
  })

  const [fetchDeleteListener, { data: deletingListener, loading: deletingLoading, error: deletingError, called: deletingCalled }] = useDeleteListenerRegisterMutation({
    fetchPolicy: 'no-cache',
  })

  const [fetchRecoverListener, { data: recoverListener, loading: recoverLoading, error: recoverError, called: recoverCalled }] = useRecoverListenerRegisterMutation({
    fetchPolicy: 'no-cache',
  })

  const [fetchUpdateListener, { data: updateListener, loading: updateLoading, error: updateError, called: updateCalled }] = useUpdateListenerRegisterMutation({
    fetchPolicy: 'no-cache',
  })

  const fetchList = () => {
    switch (dropDownOpt) {
      case TABLE_DROPDOWN_OPTIONS.active.value: {
        fetchGetListeners({
          variables: {
            input: {
              pagination: {
                limit: DEFAULT_PAGINATION.pageSize,
                page: page,
                totalCount: listenersData?.getListenersRegister.pagination.totalCount,
              },
            },
          },
        })

        return
      }
      case TABLE_DROPDOWN_OPTIONS.deleted.value: {
        fetchGetDeletedListeners({
          variables: {
            input: {
              pagination: {
                limit: DEFAULT_PAGINATION.pageSize,
                page: page,
                totalCount: listenersDeletedData?.getDeletedListenersRegister.pagination.totalCount,
              },
            },
          },
        })

        return
      }
    }
  }

  useEffect(() => {
    fetchList()
  }, [dropDownOpt, page])

  useEffect(() => {
    if (!deletingCalled || deletingLoading) return

    const notiFnc = deletingError ? notification.error : notification.success
    notiFnc({ message: deletingError ? MESSAGES.deleteFailed : MESSAGES.deleteSuccessfully })

    fetchList()
  }, [deletingListener, deletingCalled, deletingError, deletingLoading])

  useEffect(() => {
    if (!recoverCalled || recoverLoading) return

    const notiFnc = recoverError ? notification.error : notification.success
    notiFnc({ message: recoverError ? MESSAGES.recoverFailed : MESSAGES.recoverSuccessfully })

    fetchList()
  }, [recoverListener, recoverCalled, recoverError, recoverLoading])

  useEffect(() => {
    if (!updateCalled || updateLoading) return

    const notiFnc = updateError ? notification.error : notification.success
    notiFnc({ message: updateError ? MESSAGES.updateFailed : MESSAGES.updateSuccessfully })

    fetchList()
  }, [updateListener, updateCalled, updateError, updateLoading])

  const tableResource = useMemo(() => {
    return (isListenerActive ? listenersData?.getListenersRegister.data : listenersDeletedData?.getDeletedListenersRegister?.data) || ([] as any)
  }, [isListenerActive, listenersData, listenersDeletedData])

  const totalRecord = useMemo(() => {
    return (isListenerActive ? listenersData?.getListenersRegister.pagination.totalCount : listenersDeletedData?.getDeletedListenersRegister?.pagination.totalCount) || ([] as any)
  }, [isListenerActive, listenersData, listenersDeletedData])

  const handleChangePage = page => {
    setPage(page)
  }

  const handleDelete = id => {
    fetchDeleteListener({
      variables: {
        input: {
          id: id,
        },
      },
    })
  }

  const handleRecoverTag = id => {
    fetchRecoverListener({
      variables: {
        input: {
          id: id,
        },
      },
    })
  }

  const handleAccept = id => {
    fetchUpdateListener({
      variables: {
        input: {
          id: id,
          status: 'Completed' as any,
        },
      },
    })
  }

  const handleReject = id => {
    fetchUpdateListener({
      variables: {
        input: {
          id: id,
          status: 'Reject' as any,
        },
      },
    })
  }

  const columns = [
    {
      title: 'Tên',
      dataIndex: 'user',
      key: 'user',
      render: user => {
        return user.fullName
      },
    },
    {
      title: 'Email',
      dataIndex: 'user',
      key: 'user',
      render: user => {
        return user.email
      },
    },
    {
      title: 'Lý do',
      dataIndex: 'reason',
      key: 'reason',
    },
    {
      title: 'Trạng Thái',
      dataIndex: 'status',
      key: 'status',
      render: status => {
        let colorStatus = ''

        switch (status) {
          case 'PendingConfirmation':
            colorStatus = 'blue'
            break
          case 'Completed':
            colorStatus = 'green'
            break
          case 'Reject':
            colorStatus = 'red'
            break
        }

        return <Tag color={colorStatus}>{status}</Tag>
      },
    },
    {
      title: 'Thời Gian',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => {
        return <span>{dayjs(createdAt).format(DATE_TIME_FORMAT)}</span>
      },
    },
    {
      title: 'Nguồn',
      dataIndex: 'adsSource',
      key: 'adsSource',
    },
    {
      title: 'action',
      key: 'action',
      render: (_, record) => {
        if (isListenerActive) {
          return (
            <Space size="middle">
              {record.status === 'PendingConfirmation' && (
                <Space size="middle">
                  <Button type="primary" onClick={() => handleAccept(record.id)}>
                    Chấp Nhận
                  </Button>
                  <Button type="primary" onClick={() => handleReject(record.id)}>
                    Từ Chối
                  </Button>
                </Space>
              )}

              {record.status === 'Completed' && (
                <Button type="primary" onClick={() => handleReject(record.id)}>
                  Từ Chối
                </Button>
              )}

              {record.status === 'Reject' && (
                <Button type="primary" onClick={() => handleAccept(record.id)}>
                  Chấp Nhận
                </Button>
              )}

              <Popconfirm title="Bạn có chắc xoá listener này không?" onConfirm={() => handleDelete(record.id)}>
                <Button type="primary">Xóa</Button>
              </Popconfirm>
            </Space>
          )
        }
        return (
          <Space size="middle">
            <Button type="primary" onClick={() => handleRecoverTag(record.id)}>
              Khôi phục
            </Button>
          </Space>
        )
      },
    },
  ]

  return (
    <div style={{ paddingTop: 20, paddingBottom: 20 }}>
      <Title>Danh sách Listener</Title>
      <Divider plain></Divider>

      {!listenersLoading && listenersError && <Alert type="error" message={listenersError?.message} banner />}

      <ListWithOptions isDeletedList={!!me.scp?.includes(RolesConstants.getListenersWasDeleted)} onChange={setDropdownOpt} />

      <Form form={form} component={false}>
        <Table dataSource={tableResource} loading={listenersLoading} pagination={false} columns={columns} rowKey="id" />
      </Form>

      <div style={{ marginTop: 5 }}>
        <Pagination current={page} onChange={handleChangePage} pageSize={DEFAULT_PAGINATION.pageSize} total={totalRecord} />
      </div>
    </div>
  )
}
