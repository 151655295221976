import { Alert, Button, Col, Form, Input, notification, Row, Spin, Typography } from 'antd'
import React, { useEffect } from 'react'
import { MESSAGES } from 'utils/constants'
import { useGetTagQuery, useUpdateTagMutation } from '../../../../graphql/autogenerated'

const { Title } = Typography
export function EditTagPage(props: any) {
  const id = props.match.params.id
  const [form] = Form.useForm()
  const {
    data: tagData,
    loading: tagLoading,
    error: tagError,
  } = useGetTagQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        id: id,
      },
    },
  })
  const handleOnChange = (values: any) => {}

  const [fetchUpdateTag, { data: updateData, loading: updateLoading, error: updateError, called: updateCalled }] = useUpdateTagMutation({})

  const handleUpdate = async (values: any) => {
    try {
      const data = await form.validateFields()
      fetchUpdateTag({
        variables: {
          input: {
            id: id,
            name: data?.name,
          },
        },
      })
    } catch (error) {}
  }

  useEffect(() => {
    if (tagLoading || tagError) return
    form.setFieldsValue({
      name: tagData?.getTag?.name,
    })
  }, [tagLoading, tagError, tagData, form])

  useEffect(() => {
    if (!updateCalled || updateLoading) return

    const notiFnc = updateError ? notification.error : notification.success
    notiFnc({
      message: updateError ? MESSAGES.updateFailed : MESSAGES.updateSuccessfully,
    })
  }, [updateCalled, updateError, updateLoading, updateData])
  return (
    <div style={{ paddingTop: 20, paddingBottom: 20 }}>
      <Title>Cập nhật tag</Title>
      {!updateLoading && !!updateError && <Alert type="error" message={updateError.message} banner />}
      <Spin tip="Loading..." spinning={tagLoading}>
        <Form layout="vertical" form={form} onFinish={handleUpdate} onFieldsChange={handleOnChange}>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item label="Tên tag" hasFeedback name={'name'} rules={[{ required: true, message: 'Vui lòng nhập tên thẻ' }]}>
                <Input placeholder="Thêm tên thẻ" autoFocus={true} />
              </Form.Item>
            </Col>
          </Row>
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Cập nhật
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </Spin>
    </div>
  )
}
