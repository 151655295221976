import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { IRouteComponentProps } from 'types/Utils'
import { authentication } from 'utils/authentication'
import { useGuard } from 'utils/hooks/guard'

export function RouteExtension({ component: Component, isAuth = false, location, path, ...otherProps }: IRouteComponentProps) {
  const { isNext, me } = useGuard({ isAuth })
  const isLogin = !!(me['username'] || me['email'])

  const isExpired=authentication.removeTokenIfExpired()
    
  if(isExpired){
    window.location.href='/sign-in'
  }

  if (!isNext) {
    return <Redirect to={{ pathname: '/sign-in', state: { from: location } }} />
  }

  if (!isLogin && isAuth) {
    return (
      <>
        <Route {...otherProps} path={path} render={_ => <div>checking...</div>} />
      </>
    )
  }

  return (
    <>
      <Route {...otherProps} path={path} render={_props => (Component ? <Component {..._props} me={me} /> : null)} />
    </>
  )
}
