import isEqual from 'lodash/isEqual'
import isUndefined from 'lodash/isUndefined'
import { isChangedArray } from './array'

export const isChangedField = (prev: any, next: any) => {
  if (Array.isArray(prev) && Array.isArray(next)) {
    return isChangedArray(prev, next)
  }

  return !isEqual(prev, next)
}

export const getChangedData = <T extends Record<string, any>>(prev: T, next: Record<string, any>) => {
  const changed = {} as unknown as T

  Object.keys({ ...prev, ...next }).forEach(key => {
    if (isChangedField(prev[key], next[key])) {
      if (!isUndefined(next[key])) {
        ;(changed as any)[key] = next[key]
      }
    }
  })

  return changed
}

export const removeTypename = <T>(obj: T): T => {
  if (!obj) return obj

  if (Array.isArray(obj)) {
    return obj.map(item => removeTypename(item)) as any
  }

  if (typeof obj === 'object') {
    const newObj = {} as any
    Object.keys(obj).forEach(key => {
      if (key !== '__typename') {
        newObj[key] = removeTypename((obj as any)[key])
      }
    })
    return newObj as T
  }

  return obj
}
