import React, { FC, useEffect, useState } from 'react'
import { HEADING_SECTION } from 'utils/constants'

export const ExtractHeadingContent: FC<{ selectorOrHtml?: string }> = ({ selectorOrHtml = '#blog-content' }) => {
  const [nestedHeadings, setNestedHeadings] = useState<any[]>([])

  useEffect(() => {
    // Replace 'getHeadingsData' with the actual logic to fetch headings data
    const data = getHeadingsData(selectorOrHtml)
    setNestedHeadings(data)
  }, [selectorOrHtml])

  return (
    <nav className="overflow-y-auto dark-scrollbar" aria-label="Table of contents">
      <Headings headings={nestedHeadings} />
    </nav>
  )
}

const Headings = ({ headings }) => {
  const [activeId, setActiveId] = useState('')
  const [currentHeadings, setCurrentHeadings] = useState(headings || [])

  useEffect(() => {
    setCurrentHeadings(headings)
  }, [headings])

  const handleItemClick = (e, heading) => {
    // e.preventDefault()
    // if (heading.class) {
    //   document.querySelector(`.${heading.class}`)?.scrollIntoView({
    //     behavior: 'smooth',
    //   })
    // }
    // setActiveId(heading.id)
  }

  return (
    <div style={{ maxHeight: '80vh', padding: '20px 0', overflowY: 'auto' }}>
      <ul style={{ listStyleType: 'disc', paddingLeft: '1rem', marginBottom: '1rem' }}>
        {currentHeadings.map(heading => (
          <li
            key={heading.id}
            style={{
              cursor: 'pointer',
              fontSize: '0.875rem',
              transition: 'all 500ms',
              marginLeft: heading.id === activeId ? '1rem' : '0',
              color: heading.id === activeId ? '#fff' : '#7A7A7A',
              fontWeight: heading.id === activeId ? 'bold' : 'normal',
            }}
          >
            <span aria-hidden="true" onClick={e => handleItemClick(e, heading)} style={{ display: 'inline-block', color: 'rgba(0, 0, 0, 0.88)' }}>
              {heading.title}
            </span>
            {heading.items && heading.items.length > 0 && (
              <ul style={{ listStyleType: 'disc', marginLeft: '2rem', marginBottom: '0.5rem' }}>
                {heading.items.map(child => (
                  <li
                    key={child.id}
                    style={{
                      cursor: 'pointer',
                      fontWeight: child.id === activeId ? 'bold' : 'normal',
                      color: child.id === activeId ? '#ccc' : 'inherit',
                    }}
                  >
                    <span aria-hidden="true" onClick={e => handleItemClick(e, child)} style={{ display: 'inline-block', color: 'rgba(0, 0, 0, 0.88)' }}>
                      {child.title}
                    </span>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}

interface Heading {
  id: string
  title: string
  items: Partial<Heading>[]
  class?: string
}

const getClassName = (heading: HTMLElement) => {
  const classList = heading.classList
  const className = Array.from(classList).find(className => className.startsWith(HEADING_SECTION))
  return className
}

export const getNestedHeadings = (headingElements: HTMLElement[]) => {
  const nestedHeadings: Heading[] = []

  headingElements.forEach((heading, index) => {
    try {
      const { innerText: title, id } = heading

      if (heading.nodeName === 'H2') {
        const class$ = getClassName(heading)

        nestedHeadings.push({ id, title: title, items: [], class: class$ })
      } else if (heading.nodeName === 'H3' && nestedHeadings.length > 0) {
        const class$ = getClassName(heading)

        nestedHeadings[nestedHeadings.length - 1].items.push({
          id,
          title,
          class: class$,
        })
      }
    } catch (error) {}
  })

  return nestedHeadings
}

export const getHeadingsData = (selectorOrHtmlString?: string) => {
  // check if selectorOrHtmlString is a string then create a dom element and pass it to getNestedHeadings
  // else pass the selectorOrHtmlString to getNestedHeadings
  let myDocument: Document
  if (typeof selectorOrHtmlString === 'string' && (selectorOrHtmlString.startsWith('<') || !selectorOrHtmlString.startsWith('#'))) {
    const parser = new DOMParser()
    const htmlDoc = parser.parseFromString(selectorOrHtmlString, 'text/html')
    myDocument = htmlDoc
  } else {
    myDocument = !selectorOrHtmlString ? document : (document.querySelector(selectorOrHtmlString!) as any)
  }

  if (!myDocument) return []
  const headingElements = Array.from(myDocument!.querySelectorAll('h2, h3'))

  const newNestedHeadings = getNestedHeadings(headingElements as unknown as HTMLElement[])
  return newNestedHeadings
}
