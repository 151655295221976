import isEqual from 'lodash/isEqual'

export function isChangedArray(prevArray, nextArray) {
  if (prevArray.length !== nextArray.length) {
    return true
  }

  const sortedPrevArray = prevArray.sort()
  const sortedNextArray = nextArray.sort()
  return !isEqual(sortedPrevArray, sortedNextArray)
}
