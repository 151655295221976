import { TagOutlined } from '@ant-design/icons'
import { Alert, Button, Divider, Form, notification, Pagination, Popconfirm, Space, Table, Tag, Typography } from 'antd'
import { selectMe } from 'app/redux/user/selectors'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import dayjs from 'utils/dayjs'
import { RolesConstants } from 'utils/roles'
import { useDeleteBlogMutation, useGetBlogsLazyQuery, useGetDeletedBlogsLazyQuery, useRecoverBlogMutation } from '../../../../graphql/autogenerated'
import { DATE_TIME_FORMAT, DEFAULT_PAGINATION, MESSAGES, TABLE_DROPDOWN_OPTIONS } from '../../../../utils/constants'
import { getPathByName } from '../../../../utils/utils'
import { ListWithOptions } from '../../../components/list-with-options/index'

const Title = Typography
export function ListBlogPage() {
  const me = useSelector(selectMe)
  const [form] = Form.useForm()
  const history = useHistory()
  const [page, setPage] = useState(1)
  const [fetchGetsBlog, { data: blogsData, loading: blogsLoading, error: blogsError, refetch: blogsRefetch }] = useGetBlogsLazyQuery({
    fetchPolicy: 'no-cache',
  })

  const [fetchGetDeletedBlogs, { data: blogsDeletedData, loading: blogsDeletedLoading, error: blogsDeletedError }] = useGetDeletedBlogsLazyQuery({
    fetchPolicy: 'no-cache',
  })

  const [fetchDeleteBlog, { loading: deletingLoading, error: deletingError, called: deletingCalled }] = useDeleteBlogMutation({
    fetchPolicy: 'no-cache',
  })
  const [fetchRecoverBlog, { data: recoverBlogData, loading: recoverBlogLoading, error: recoverBlogError, called: recoverBlogCalled }] = useRecoverBlogMutation({
    fetchPolicy: 'no-cache',
  })
  const [dropDownOpt, setDropdownOpt] = useState(TABLE_DROPDOWN_OPTIONS.active.value)

  const isBlogActive = useMemo(() => {
    return dropDownOpt === TABLE_DROPDOWN_OPTIONS.active.value
  }, [dropDownOpt])

  const fetchList = () => {
    switch (dropDownOpt) {
      case TABLE_DROPDOWN_OPTIONS.active.value: {
        fetchGetsBlog({
          variables: {
            input: {
              pagination: {
                limit: DEFAULT_PAGINATION.pageSize,
                page: page,
                totalCount:  blogsData?.getBlogs.pagination.totalCount,
              },
            },
          },
        })
        return
      }
      case TABLE_DROPDOWN_OPTIONS.deleted.value: {
        fetchGetDeletedBlogs({
          variables: {
            input: {
              pagination: {
                limit: DEFAULT_PAGINATION.pageSize,
                page: page,
                totalCount: blogsDeletedData?.getDeletedBlogs.pagination.totalCount,
              },
            },
          },
        })
      }
    }
  }
  useEffect(() => {
    fetchList()
  }, [dropDownOpt, page])

  useEffect(() => {
    if (!deletingCalled || deletingLoading) return
    const notiFnc = deletingError ? notification.error : notification.success
    notiFnc({ message: deletingError ? MESSAGES.deleteFailed : MESSAGES.deleteSuccessfully })
    fetchList()
  }, [deletingError, deletingLoading, deletingCalled])

  useEffect(() => {
    if (!recoverBlogCalled || recoverBlogLoading) return
    const notiFnc = recoverBlogLoading ? notification.error : notification.success
    notiFnc({ message: recoverBlogError ? MESSAGES.recoverFailed : MESSAGES.recoverSuccessfully })
    fetchList()
  }, [recoverBlogData, recoverBlogCalled, recoverBlogError, recoverBlogLoading])

  const handleEdit = useCallback(
    record => {
      const pathname = getPathByName('blog-edit', { id: record.id })
      history.push(pathname)
    },
    [history],
  )

  const handleChangePage = page => {
    setPage(page)
  }

  const handleDelete = id => {
    fetchDeleteBlog({
      variables: {
        input: { id: id },
      },
    })
  }

  const handleRecoverBlog = (id: string) => {
    fetchRecoverBlog({
      variables: {
        input: {
          id: id,
        },
      },
    })
  }

  const tableResource = useMemo(() => {
    return (isBlogActive ? blogsData?.getBlogs.data : blogsDeletedData?.getDeletedBlogs?.data) || ([] as any)
  }, [isBlogActive, blogsData, blogsDeletedData])

  const totalRecord = useMemo(() => {
    return (isBlogActive ? blogsData?.getBlogs.pagination.totalCount : blogsDeletedData?.getDeletedBlogs?.pagination.totalCount) || ([] as any)
  }, [isBlogActive, blogsData, blogsDeletedData])

  const columns = [
    {
      title: 'Tiêu đề',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Tác giả',
      dataIndex: 'createdBy',
      key: 'author',
      render: createdBy => {
        return createdBy.fullName || createdBy.email
      },
    },

    {
      title: 'Chủ đề',
      dataIndex: 'categories',
      key: 'categories',
      render: categories => (
        <span>
          {categories?.map(tag => {
            return (
              <Tag color="processing" key={tag.id}>
                {tag.name}
              </Tag>
            )
          })}
        </span>
      ),
    },
    {
      title: 'Thẻ',
      dataIndex: 'tags',
      key: 'tags',
      render: tags => (
        <span>
          {tags?.map(tag => {
            return (
              <Tag icon={<TagOutlined />} color="success" key={tag.id}>
                {tag.name}
              </Tag>
            )
          })}
        </span>
      ),
    },
    {
      title: 'Thời gian',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => {
        return <span>{dayjs(createdAt).format(DATE_TIME_FORMAT)}</span>
      },
    },
    {
      title: 'action',
      key: 'action',
      render: (_, record) => {
        if (isBlogActive) {
          return (
            <Space size="middle">
              <Button type="primary" onClick={() => handleEdit(record)}>
                Sửa
              </Button>
              <Popconfirm title="Bạn có chắc xoá bài viết này không?" onConfirm={() => handleDelete(record.id)}>
                <Button type="primary">Xóa</Button>
              </Popconfirm>
            </Space>
          )
        }
        return (
          <Space size="middle">
            <Button type="primary" onClick={() => handleRecoverBlog(record.id)}>
              Khôi phục
            </Button>
          </Space>
        )
      },
    },
  ]
  return (
    <div style={{ paddingTop: 20, paddingBottom: 20 }}>
      <Title>Danh sách bài viết</Title>
      <Divider plain></Divider>

      {((!blogsLoading && blogsError) || (!blogsDeletedLoading && blogsDeletedError)) && <Alert type="error" message={blogsError?.message || blogsDeletedError?.message} banner />}

      <ListWithOptions isDeletedList={!!me.scp?.includes(RolesConstants.getBlogsWasDeleted)} onChange={setDropdownOpt} />
      <Form form={form} component={false}>
        <Table dataSource={tableResource} loading={blogsLoading || blogsDeletedLoading} pagination={false} columns={columns} rowKey="id" />
      </Form>
      <div style={{ marginTop: 5 }}>
        <Pagination current={page} onChange={handleChangePage} pageSize={DEFAULT_PAGINATION.pageSize} total={totalRecord} />
      </div>
    </div>
  )
}
