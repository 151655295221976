import jwt_decode from 'jwt-decode'
import moment from 'moment'
import Cookies from 'universal-cookie'
import { ACCESS_TOKEN_KEY } from 'utils/constants'

const cookies = new Cookies()

class Authentication {
  getToken() {
    return cookies.get(ACCESS_TOKEN_KEY)
  }

  getBearerToken() {
    return this.getToken()
  }

  logout() {
    cookies.remove(ACCESS_TOKEN_KEY, { path: '/', domain: window.location.hostname, httpOnly: false })

    // Remove old cookies from the previous version
    cookies.remove(ACCESS_TOKEN_KEY)
    window.location.href = '/sign-in'
    // window.location.href = configs.frontendDomain + '/auth/logout'
  }

  setToken(token: string) {
    // cookies.set(ACCESS_TOKEN_KEY, `Bearer ${token}`)

    cookies.set(ACCESS_TOKEN_KEY, `Bearer ${token}`, { path: '/', domain: window.location.hostname, httpOnly: false })
  }

  isLogged() {
    return !!this.getToken()
  }

  getScp() {
    const token = this.getToken()
    const decode = token ? jwt_decode(token) : {}
    // @ts-ignore
    return decode?.scp || []
  }

  isExpired() {
    const token = this.getToken()
    const decode = token ? jwt_decode(token) : {}
    // @ts-ignore
    const isExpired = !token || decode.exp < moment().unix()
    return isExpired
  }

  removeTokenIfExpired() {
    const isExpired = this.isExpired()
    const token = this.getToken()

    if (token && isExpired) {
      this.logout()
    }
    return isExpired
  }

  checkRole(role: string) {
    const scp = this.getScp()
    if (scp.includes('all')) {
      return true
    }
    return scp.includes(role)
  }
}

export const authentication = new Authentication()
