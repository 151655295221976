import { Alert, Button, Col, Form, Input, notification, Radio, Row, Spin, Typography } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { AvatarUpload } from 'app/components/uploads/AvatarUpload'
import { useCreateCourseCategoryMutation } from 'graphql/autogenerated'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { MESSAGES, ROUTE_NAMES } from 'utils/constants'
import { generateSlug } from 'utils/helpers'
import { getPathByName } from 'utils/utils'

const { Title } = Typography

export function AddCourseCategoryPage() {
  const [form] = Form.useForm()
  const [flag, setFlag] = useState(false)
  const history = useHistory()

  const [handleCreateCourseCategory, { data: createCourseCategoryData, loading: createCourseCategoryLoading, error: createCourseCategoryError, called: createCourseCategoryCalled }] =
    useCreateCourseCategoryMutation({
      onError: (err: any) => {},
    })

  useEffect(() => {
    if (!createCourseCategoryCalled || createCourseCategoryLoading) return
    setFlag(true)
    const notiFnc = createCourseCategoryError ? notification.error : notification.success
    notiFnc({
      message: createCourseCategoryError ? MESSAGES.createFailed : MESSAGES.createSuccessfully,
    })
    form.resetFields()
    setFlag(false)
  }, [createCourseCategoryData, createCourseCategoryError, createCourseCategoryLoading, createCourseCategoryCalled, form])

  const handleGotoEditPage = useCallback(
    (id: string) => {
      const pathname = getPathByName(ROUTE_NAMES.courseCategoryEdit, { id: id })
      setTimeout(() => {
        history.push({ pathname })
      }, 100)
    },
    [history],
  )

  const handleCreate = async () => {
    const row = await form.validateFields()
    try {
      handleCreateCourseCategory({
        variables: {
          input: {
            name: row.name,
            slug: row.slug,
            description: row.description,
            image: row.image,
          },
        },
      }).then(data => {
        if (data.data?.createCourseCategory.id) {
          handleGotoEditPage(data.data?.createCourseCategory.id)
        }
      })
    } catch (error) {}
  }

  const handleTitleChange = event => {
    const title = event.target.value as string
    form.setFieldValue('title', title)
    const isCustomSlug = form.getFieldValue('isCustomSlug') as boolean
    if (!isCustomSlug) {
      const slug = generateSlug({ baseSlug: title })
      form.setFieldValue('slug', slug)
    }
  }
  const handleOnChange = e => {}
  return (
    <div style={{ paddingTop: 20, paddingBottom: 20 }}>
      <Title>Tạo chuyên mục khóa học</Title>
      {!createCourseCategoryLoading && !!createCourseCategoryError && <Alert type="error" message={createCourseCategoryError.message} banner />}
      <Spin tip="Loading..." spinning={createCourseCategoryLoading}>
        <Form layout="vertical" form={form} onFinish={handleCreate} onFieldsChange={handleOnChange} initialValues={{ isCustomSlug: false }}>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item label="Tên chuyên mục" hasFeedback name={'name'} rules={[{ required: true, message: 'Vui lòng nhập tên chuyên mục' }]}>
                <Input onChange={handleTitleChange} placeholder="Thêm tên chuyên mục" autoFocus={true} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Form.Item noStyle name={'isCustomSlug'}>
              <Radio.Group>
                <Radio value={false}>Mặc định</Radio>
                <Radio value={true}>Chỉnh đường dẫn</Radio>
              </Radio.Group>
            </Form.Item>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item shouldUpdate={(preValues, currentValues) => preValues.isCustomSlug !== currentValues.isCustomSlug}>
                {({ getFieldValue }) => {
                  const isDisable = !getFieldValue('isCustomSlug')
                  return (
                    <Form.Item
                      noStyle
                      hasFeedback
                      name={'slug'}
                      rules={[{ required: true, message: 'slug là bắt buộc' }]}
                      shouldUpdate={(preValues, currentValues) => preValues.isCustomSlug !== currentValues.isCustomSlug}
                    >
                      <Input disabled={isDisable} placeholder="Đường dẫn tĩnh"></Input>
                    </Form.Item>
                  )
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item label="Mô tả" name="description">
                <TextArea
                  style={{
                    height: '200px',
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Form.Item label="Hình ảnh" name={'image'}>
              <AvatarUpload
                onUpload={img => {
                  form.setFieldsValue({
                    image: img.url,
                  })
                }}
              />
            </Form.Item>
          </Row>
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={flag}>
                Tạo chuyên mục khóa học
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </Spin>
    </div>
  )
}
