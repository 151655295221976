import { Alert, Button, Col, Form, Input, notification, Row, Spin, Typography } from 'antd'
import { useCreateTagMutation } from 'graphql/autogenerated'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { MESSAGES, ROUTE_NAMES } from 'utils/constants'
import { getPathByName } from 'utils/utils'

const { Title } = Typography
export function AddTagPage() {
  const [form] = Form.useForm()
  const [flag, setFlag] = useState(false)
  const history = useHistory()

  const [handleCreateTag, { data: createTagData, loading: createTagLoading, error: createTagError, called: createTagCalled }] = useCreateTagMutation({
    onError: (err: any) => {},
  })

  const handleGotoEditPage = useCallback(
    (id: string) => {
      const pathname = getPathByName(ROUTE_NAMES.tagEdit, { id: id })
      setTimeout(() => {
        history.push({ pathname })
      }, 100)
    },
    [history],
  )

  const handleCreate = async () => {
    const row = await form.validateFields()

    try {
      handleCreateTag({
        variables: {
          input: {
            name: row.name,
          },
        },
      }).then(data => {
        if (data.data?.createTag.id) {
          handleGotoEditPage(data.data?.createTag.id)
        }
      })
    } catch (error) {}
  }
  const handleOnChange = e => {}

  useEffect(() => {
    if (!createTagCalled || createTagLoading) return
    const notiFnc = createTagError ? notification.error : notification.success
    notiFnc({
      message: createTagError ? MESSAGES.createFailed : MESSAGES.createSuccessfully,
    })
    form.resetFields()
    setFlag(false)
  }, [createTagError, createTagLoading, createTagCalled])

  return (
    <div style={{ paddingTop: 20, paddingBottom: 20 }}>
      <Title>Tạo thẻ</Title>
      {!createTagLoading && !!createTagError && <Alert type="error" message={createTagError.message} banner />}
      <Spin tip="Loading..." spinning={createTagLoading}>
        <Form layout="vertical" form={form} onFinish={handleCreate} onFieldsChange={handleOnChange}>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item label="Tên thẻ" hasFeedback name={'name'} rules={[{ required: true, message: 'Vui lòng nhập tên thẻ' }]}>
                <Input placeholder="Thêm tên thẻ" autoFocus={true} />
              </Form.Item>
            </Col>
          </Row>
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Tạo
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </Spin>
    </div>
  )
}
