import React from 'react'
import DOMPurify from 'dompurify'

interface HtmlProps {
  data: string
}
export const RawToHtml = ({ data }: HtmlProps) => {
  const sanitizedHtmlContent = DOMPurify.sanitize(data || '')
  return <div dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }}></div>
}
