import { Alert, Button, Col, Form, Input, Radio, Row, Select, Spin, Typography, notification } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { Editor } from 'app/components/editor'
import { SubModuleType, UrlSource, useCreateSubModuleMutation, useGetCourseLazyQuery } from 'graphql/autogenerated'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import 'react-quill/dist/quill.snow.css'
import { useHistory } from 'react-router-dom'
import { MESSAGES, ROUTE_NAMES } from 'utils/constants'
import { generateSlug } from 'utils/helpers'
import { getPathByName } from 'utils/utils'

const { Title } = Typography

export function AddSubModulePage(props) {
  const courseId = props.match.params.courseId
  const moduleId = props.match.params.moduleId
  const [typeSubModule, setTypeSubModule] = useState(null)

  const [form] = Form.useForm()
  const [flag, setFlag] = useState(false)
  const history = useHistory()

  const TypeSubModule = [
    {
      label: 'Video',
      value: SubModuleType.Video,
    },
    {
      label: 'Rich Text',
      value: SubModuleType.RichText,
    },
  ]

  const TypeSource = [
    {
      label: 'Youtube',
      value: UrlSource.Youtube,
    },
    // {
    //   label: 'Vimeo',
    //   value: UrlSource.Vimeo,
    // },
    // {
    //   label: 'Dailymotion',
    //   value: UrlSource.Dailymotion,
    // },
    // {
    //   label: 'Facebook',
    //   value: UrlSource.Facebook,
    // },
    // {
    //   label: 'Twitch',
    //   value: UrlSource.Twitch,
    // },
    // {
    //   label: 'Wistia',
    //   value: UrlSource.Wistia,

    // },
    // {
    //   label: 'Webm',
    //   value: UrlSource.Webm,
    // }
  ]

  const [fetchGetAllModules, { data: fetchGetAllModulesData, loading: modulesLoading, error: modulesError, refetch: moduleDetailRefetch }] = useGetCourseLazyQuery({
    fetchPolicy: 'no-cache',
  })

  const [handleCreateSubModule, { data: createSubModuleData, loading: createSubModuleLoading, error: createSubModuleError, called: createSubModuleCalled }] = useCreateSubModuleMutation({
    onError: (err: any) => {},
  })

  const getAllModules = useMemo(() => {
    return fetchGetAllModulesData?.getCourse?.data.modules
  }, [fetchGetAllModulesData])

  const handleGotoEditModulePage = useCallback(
    (id: string) => {
      const pathname = getPathByName(ROUTE_NAMES.moduleEdit, { courseId: id })
      setTimeout(() => {
        history.push({ pathname })
      }, 100)
    },
    [history],
  )

  const handleTitleChange = event => {
    const title = event.target.value as string
    form.setFieldValue('title', title)
    const isCustomSlug = form.getFieldValue('isCustomSlug') as boolean

    if (!isCustomSlug) {
      const slug = generateSlug({ baseSlug: title })
      form.setFieldValue('slug', slug)
    }
  }

  const handleCreate = async () => {
    const row = await form.validateFields()

    try {
      handleCreateSubModule({
        variables: {
          input: {
            title: row.title,
            description: row.description,
            slug: row.slug,
            moduleId: row.moduleId,
            type: row.type,
            richText:
              row.type === SubModuleType.RichText
                ? {
                    title: row?.titleRichText,
                    content: row?.content,
                  }
                : null,
            video:
              row.type === SubModuleType.Video
                ? {
                    title: row?.titleVideo,
                    url: row?.url,
                    source: row?.source,
                  }
                : null,
          },
        },
      }).then(data => {
        if (data.data?.createSubModule.data) {
          handleGotoEditModulePage(data.data?.createSubModule.data.id)
        }
      })
    } catch (error) {}
  }

  useEffect(() => {
    if (modulesLoading || modulesError) return

    form.setFieldsValue({
      moduleId: moduleId,
    })
  }, [modulesLoading, modulesError, fetchGetAllModulesData, form])

  useEffect(() => {
    if (!createSubModuleCalled || createSubModuleLoading) return
    setFlag(true)
    const notiFnc = createSubModuleError ? notification.error : notification.success
    notiFnc({
      message: createSubModuleError ? MESSAGES.createFailed : MESSAGES.createSuccessfully,
    })
    form.resetFields()
    setFlag(false)
  }, [createSubModuleData, createSubModuleError, createSubModuleLoading, createSubModuleCalled, form])

  const handleOnChange = e => {}

  useEffect(() => {
    fetchGetAllModules({
      variables: {
        input: {
          id: courseId,
        },
      },
    })
  }, [courseId])

  return (
    <div style={{ paddingTop: 20, paddingBottom: 20 }}>
      <Title>Tạo subModule</Title>
      {!createSubModuleLoading && !!createSubModuleError && <Alert type="error" message={createSubModuleError.message} banner />}
      <Spin tip="Loading..." spinning={createSubModuleLoading}>
        <Form layout="vertical" form={form} onFinish={handleCreate} onFieldsChange={handleOnChange} initialValues={{ isCustomSlug: false, publishDate: null }}>
          <Row gutter={[16, 8]}>
            <Col span={8}>
              <Form.Item label="Tên module" hasFeedback name={'moduleId'} rules={[{ required: true, message: 'Vui lòng nhập tên module' }]}>
                <Select options={getAllModules?.map((item: any) => ({ label: <span>{item.title}</span>, value: item.id }))} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 8]}>
            <Col span={8}>
              <Form.Item label="Tên bài học" hasFeedback name={'title'} rules={[{ required: true, message: 'Vui lòng nhập tên bài học' }]}>
                <Input onChange={handleTitleChange} placeholder="Thêm tên bài học " autoFocus={true} />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ marginBottom: 10 }}>
            <Form.Item noStyle name={'isCustomSlug'}>
              <Radio.Group>
                <Radio value={false}>Mặc định</Radio>
                <Radio value={true}>Chỉnh đường dẫn</Radio>
              </Radio.Group>
            </Form.Item>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item shouldUpdate={(preValues, currentValues) => preValues.isCustomSlug !== currentValues.isCustomSlug}>
                {({ getFieldValue }) => {
                  const isDisable = !getFieldValue('isCustomSlug')
                  return (
                    <Form.Item
                      noStyle
                      hasFeedback
                      name={'slug'}
                      rules={[{ required: true, message: 'slug là bắt buộc' }]}
                      shouldUpdate={(preValues, currentValues) => preValues.isCustomSlug !== currentValues.isCustomSlug}
                    >
                      <Input disabled={isDisable} placeholder="Đường dẫn tĩnh"></Input>
                    </Form.Item>
                  )
                }}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item label="Mô tả" name="description" rules={[{ required: true, message: 'Vui lòng nhập mô tả cho module' }]}>
                <TextArea
                  style={{
                    height: '200px',
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={4}>
              <Form.Item label="nội dung" name="type" rules={[{ required: true, message: 'Vui lòng chon nội dung' }]}>
                <Select options={TypeSubModule} onChange={value => setTypeSubModule(value)} />
              </Form.Item>
            </Col>
          </Row>
          {typeSubModule === SubModuleType.RichText && (
            <>
              <Row gutter={[16, 8]}>
                <Col span={8}>
                  <Form.Item label="tiêu đề " hasFeedback name={'titleRichText'} rules={[{ required: true, message: 'Vui lòng nhập tên bài học' }]}>
                    <Input placeholder="Thêm tiêu đề " autoFocus={true} />
                  </Form.Item>
                </Col>
              </Row>

              <div>
                <Form.Item style={{ margin: 0, backgroundColor: 'white' }} label="Nội dung" name={'content'} rules={[{ required: true, message: 'Vui lòng nhập nội dung' }]}>
                  <Editor
                    style={{
                      marginBottom: 0,
                      border: '1px solid #d9d9d9',
                    }}
                    placeholder="content"
                    onChange={content => form.setFieldsValue({ content: content })}
                  />
                </Form.Item>
              </div>
            </>
          )}
          {typeSubModule === SubModuleType.Video && (
            <div>
              <Row gutter={[16, 8]}>
                <Col span={8}>
                  <Form.Item label="tiêu đề Video" hasFeedback name={'titleVideo'} rules={[{ required: true, message: 'Vui lòng nhập tên bài học' }]}>
                    <Input placeholder="Thêm tiêu đề " autoFocus={true} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Form.Item label="Chèn link video" name="url" rules={[{ required: true, message: 'Vui lòng chon nội dung' }]}>
                    <Input placeholder="Chèn link video" autoFocus={true} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 8]}>
                <Col span={8}>
                  <Form.Item label="Kiểu source" hasFeedback name={'source'} rules={[{ required: true, message: 'Vui lòng chọn kiểu source' }]}>
                    <Select options={TypeSource?.map((item: any) => ({ label: <span>{item.label}</span>, value: item.value }))} />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          )}

          <Col span={4} style={{ marginTop: 20 }}>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={flag}>
                Tạo SubModule
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </Spin>
    </div>
  )
}
