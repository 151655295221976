import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, split } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { authentication } from 'utils/authentication'
import { BACKEND_API } from 'utils/config'

const middlewareLink = new ApolloLink((operation, forward) => {
  const token = authentication.getToken()
  operation.setContext({
    headers: {
      Authorization: `${token}` || null,
    },
  })
  return forward(operation)
})
const httpLink = new HttpLink({
  uri: BACKEND_API.api,
  // credentials: 'include',
})

const wsLink = new WebSocketLink({
  uri: BACKEND_API.graphqlWs,
  options: {
    reconnect: true,
  },
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) graphQLErrors.map(({ message, locations, path }) => console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`))

  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  wsLink,
  httpLink,
)

const link = ApolloLink.from([errorLink, middlewareLink, splitLink])

export const apolloClient = new ApolloClient({
  link,
  defaultOptions: {},
  // ...other arguments...
  cache: new InMemoryCache(),
})
