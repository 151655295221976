import { Alert, Button, Col, Form, Input, notification, Radio, Row, Spin, Typography } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { AvatarUpload } from 'app/components/uploads/AvatarUpload'
import { useGetCourseCategoryQuery, useUpdateCourseCategoryMutation } from 'graphql/autogenerated'
import React, { useEffect, useState } from 'react'
import { MESSAGES } from 'utils/constants'
import { generateSlug } from 'utils/helpers'

const { Title } = Typography

export function UpdateCourseCategoryPage(props: any) {
  const courseCategoryId = props.match.params.id

  const [form] = Form.useForm()
  const [flag, setFlag] = useState(false)

  const [handleUpdateCourseCategory, { data: updateCourseCategoryData, loading: updateCourseCategoryLoading, error: updateCourseCategoryError, called: updateCourseCategoryCalled }] =
    useUpdateCourseCategoryMutation()
  const {
    data: courseCategoryData,
    loading: courseCategoryLoading,
    error: courseCategoryError,
    called: CourseCategoryCalled,
  } = useGetCourseCategoryQuery({
    variables: {
      input: {
        id: courseCategoryId,
      },
    },
  })

  const handleUpdate = async () => {
    const row = await form.validateFields()
    try {
      handleUpdateCourseCategory({
        variables: {
          input: {
            id: courseCategoryId,
            name: row.name,
            slug: row.slug,
            description: row.description,
            image: row.image,
          },
        },
      })
    } catch (error) {}
  }

  const handleTitleChange = event => {
    const title = event.target.value as string
    form.setFieldValue('title', title)
    const isCustomSlug = form.getFieldValue('isCustomSlug') as boolean
    if (!isCustomSlug) {
      const slug = generateSlug({ baseSlug: title })
      form.setFieldValue('slug', slug)
    }
  }

  const handleOnChange = e => {}
  useEffect(() => {
    if (courseCategoryLoading || courseCategoryError) return
    const courseCategory = courseCategoryData?.getCourseCategory.data
    form.setFieldsValue({
      ...courseCategory,
    })
  }, [CourseCategoryCalled, courseCategoryData, courseCategoryError, courseCategoryLoading, form])

  useEffect(() => {
    if (!updateCourseCategoryCalled || updateCourseCategoryLoading) return
    setFlag(true)

    const notiFnc = updateCourseCategoryError ? notification.error : notification.success
    notiFnc({
      message: updateCourseCategoryError ? MESSAGES.updateFailed : MESSAGES.updateSuccessfully,
    })
    // form.resetFields()
    setFlag(false)
  }, [updateCourseCategoryData, updateCourseCategoryError, updateCourseCategoryLoading, updateCourseCategoryCalled, form])

  return (
    <div style={{ paddingTop: 20, paddingBottom: 20 }}>
      <Title>Cập nhật chuyên mục khóa học</Title>
      {!updateCourseCategoryLoading && !!updateCourseCategoryError && <Alert type="error" message={updateCourseCategoryError.message} banner />}
      <Spin tip="Loading..." spinning={updateCourseCategoryLoading}>
        <Form layout="vertical" form={form} onFinish={handleUpdate} onFieldsChange={handleOnChange} initialValues={{ isCustomSlug: false }}>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item label="Tên chuyên mục" hasFeedback name={'name'} rules={[{ required: true, message: 'Vui lòng nhập tên chuyên mục' }]}>
                <Input onChange={handleTitleChange} placeholder="Thêm tên chuyên mục" autoFocus={true} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Form.Item noStyle name={'isCustomSlug'}>
              <Radio.Group>
                <Radio value={false}>Mặc định</Radio>
                <Radio value={true}>Chỉnh đường dẫn</Radio>
              </Radio.Group>
            </Form.Item>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item shouldUpdate={(preValues, currentValues) => preValues.isCustomSlug !== currentValues.isCustomSlug}>
                {({ getFieldValue }) => {
                  const isDisable = !getFieldValue('isCustomSlug')
                  return (
                    <Form.Item
                      noStyle
                      hasFeedback
                      name={'slug'}
                      rules={[{ required: true, message: 'slug là bắt buộc' }]}
                      shouldUpdate={(preValues, currentValues) => preValues.isCustomSlug !== currentValues.isCustomSlug}
                    >
                      <Input disabled={isDisable} placeholder="Đường dẫn tĩnh"></Input>
                    </Form.Item>
                  )
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item label="Mô tả" name="description">
                <TextArea
                  style={{
                    height: '200px',
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Form.Item label="Hình ảnh" name={'image'}>
              <AvatarUpload
                onUpload={img => {
                  form.setFieldsValue({
                    image: img.url,
                  })
                }}
              />
            </Form.Item>
          </Row>
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={flag}>
                Cập nhật chuyên mục khóa học
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </Spin>
    </div>
  )
}
